import { Describe, any, array, boolean, is, nullable, number, object, omit, optional, string, type } from "superstruct";
import { AAAShapefileExport } from "./fields/AAAShapefileExport";
import { AckerprofiShapefileExport } from "./fields/AckerprofiShapefileExport";
import { AgrarAntragBBExport } from "./fields/AgrarAntragBBExport";
import { AgrarMonitorShapefileExport } from "./fields/AgrarMonitorShapefileExport";
import { AgrarPortalHessenExport } from "./fields/AgrarPortalHessenExport";
import { AgravisShapefileExport } from "./fields/AgravisShapefileExport";
import { AndiExport } from "./fields/AndiExport";
import { ASDigitalExport } from "./fields/ASDigitalExport";
import { CCCShapefileExport } from "./fields/CCCShapefileExport";
import { ClaasTelematicsShapefileExport } from "./fields/ClaasTelematicsShapefileExport";
import { DDDShapefileExport } from "./fields/DDDShapefileExport";
import { eAMAShapefileExport } from "./fields/eAMAShapefileExport";
import { EEEShapefileExport } from "./fields/EEEShapefileExport";
import { ElanExport } from "./fields/ElanExport";
import { FarmActExport } from "./fields/FarmActExport";
import { Farmnet365ShapefileExport } from "./fields/Farmnet365ShapefileExport";
import { FFFShapefileExport } from "./fields/FFFShapefileExport";
import { FionaGISExport } from "./fields/FionaGISExport";
import { FloRLPExport } from "./fields/FloRLPExport";
import { GoogleKmlExport } from "./fields/GoogleKmlExport";
import { IbalisExport } from "./fields/IbalisExport";
import { JohnDeereExport } from "./fields/JohnDeereExport";
import { MyFarm24Export } from "./fields/MyFarm24Export";
import { PolarisExport } from "./fields/PolarisExport";
import { QGISShapefileExport } from "./fields/QGISShapefileExport";
import { WWWShapefileExport } from "./fields/WWWShapefileExport";
import { XXXShapefileExport } from "./fields/XXXShapefileExport";
import { YYYShapefileExport } from "./fields/YYYShapefileExport";
import { ZZZShapefileExport } from "./fields/ZZZShapefileExport";
import { Marker, MarkerStruct } from "./Marker";
import { Track, TrackStruct } from "./Track";
import { Vertex, VertexStruct } from "./Vertex";
import { computeShapeToAreaHa } from "../utils/computeShapeUtilsModel";

export type FieldTrack = Omit<Track, "customerId" | "archived">;

export class Field {
    public static DEFAULT_COLOR = "#39b54a";
    public areaHa: number;
    public shape: Vertex[];
    public name: string;
    public other: string;
    public id: string;
    public color: string;
    public officialCompanyId: string;
    public province: string;
    public customerId: string;
    public crop?: string;
    public cropBefore?: string;
    public archived: boolean;
    public tracksLength: number;
    public data?:
        | IbalisExport
        | FionaGISExport
        | ElanExport
        | AgrarPortalHessenExport
        | AgrarAntragBBExport
        | AndiExport
        | MyFarm24Export
        | eAMAShapefileExport
        | FloRLPExport
        | ASDigitalExport
        | AAAShapefileExport
        | CCCShapefileExport
        | DDDShapefileExport
        | EEEShapefileExport
        | FFFShapefileExport
        | WWWShapefileExport
        | XXXShapefileExport
        | YYYShapefileExport
        | ZZZShapefileExport
        | ClaasTelematicsShapefileExport
        | AgrarMonitorShapefileExport
        | AckerprofiShapefileExport
        | QGISShapefileExport
        | GoogleKmlExport
        | Farmnet365ShapefileExport
        | JohnDeereExport
        | PolarisExport
        | FarmActExport
        | AgravisShapefileExport;
    public markers: Marker[];
    public tracks: FieldTrack[];

    // IBALIS
    public fid: string | null;
    // FIONA
    public ud_id: string | null;
    public geo_id: string | null;
    // ELAN, ANDI
    public flik: string | null;
    // AP Hessen
    public uuid: string | null;

    constructor(initialValues?: Partial<Field>) {
        this.id = initialValues?.id ?? "";
        this.shape = initialValues?.shape ?? [];
        this.areaHa = initialValues?.areaHa ?? computeShapeToAreaHa(initialValues?.shape ?? []);
        this.name = initialValues?.name ?? "";
        this.other = initialValues?.other ?? "";
        this.color = initialValues?.color ?? Field.DEFAULT_COLOR;
        this.officialCompanyId = initialValues?.officialCompanyId ?? "";
        this.province = initialValues?.province ?? "Nicht definiert";
        this.customerId = initialValues?.customerId ?? "";
        this.crop = initialValues?.crop ?? "";
        this.cropBefore = initialValues?.cropBefore ?? "";
        this.archived = initialValues?.archived ?? false;
        this.data = initialValues?.data;
        this.markers = initialValues?.markers ?? [];
        this.tracks = initialValues?.tracks ?? [];
        this.tracksLength = initialValues?.tracksLength ?? 0;

        // IBALIS
        this.fid = initialValues?.fid ?? null;
        // FIONA
        this.ud_id = initialValues?.ud_id ?? null;
        this.geo_id = initialValues?.geo_id ?? null;
        // ELAN
        this.flik = initialValues?.flik ?? null;
        // AP Hessen
        this.uuid = initialValues?.uuid ?? null;
    }
}

export default Field;

export const FieldTrackStruct = omit(TrackStruct, ["customerId", "archived"]);

export const FieldStruct = object({
    id: string(),
    shape: array(VertexStruct),
    areaHa: number(),
    name: string(),
    other: string(),
    color: string(),
    officialCompanyId: string(),
    province: string(),
    customerId: string(),
    crop: optional(string()),
    cropBefore: optional(string()),
    tracksLength: number(),
    archived: boolean(),
    // TODO: refine this struct
    data: any(),
    markers: array(MarkerStruct),

    // IBALIS
    fid: nullable(string()),
    // FIONA
    ud_id: nullable(string()),
    geo_id: nullable(string()),
    // ELAN
    flik: nullable(string()),
    // AP Hessen
    uuid: nullable(string()),
    tracks: array(FieldTrackStruct),
}) satisfies Describe<Field>;

const IsFieldStruct = type({
    shape: array(),
    areaHa: number(),
});

export function isField(candidate: unknown): candidate is Field {
    return is(candidate, IsFieldStruct);
}
